import { graphql } from "gatsby";
import PropTypes from "prop-types";

import SimpleRichText from "./text/simpleRichText";

const KontentItemTextEft = ({ data }) => {
  const eft = data.value;

  if (!eft.length > 0) {
    return null;
  }

  return (SimpleRichText({ data: eft[0].elements.donation_account }));
};

export const query = graphql`
  fragment KontentItemTextEft on kontent_item_eft {
    id
    system {
      codename
    }
    elements {
      donation_account {
        value
        images {
          width
          url
          image_id
          height
          description
        }
        links {
          link_id
          codename
          type
          url_slug
        }
        type
        name
        modular_content {
          id
          system {
            codename
          }
          internal {
            type
          }
          ...KontentItemImage
          ...KontentItemCtaButton
        }
      }
    }
  }
`;

KontentItemTextEft.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemTextEft;
