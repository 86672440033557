import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import "./donationHeader.scss";

const DonationHeaderAfricaText = ({ description, headline, children }) => (
  <div className="donation-header donation-header--text donation-header--africa container--fullwidth">
    <Container fluid>
      <Row className="donation-header__box-container">
        <Col lg={5}>
          <div className="donation-header-text__text-header">
            <h2>{headline}</h2>
            <div className="donation-header-text__description">{description}</div>
          </div>
        </Col>
        <Col lg={7} xl={{ offset: 1, span: 6 }}>
          {children}
        </Col>
      </Row>
    </Container>
  </div>
);

DonationHeaderAfricaText.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  headline: PropTypes.string.isRequired,
};

export default DonationHeaderAfricaText;
