import React from "react";

import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import DonationBox from "./donationBox";
import KontentItemProvider from "./kontentItemProvider";
import DonationHeader from "../../components/donationHeader/donationHeader";
import backgroundImageHelper from "../image/backgroundImageHelper";

/**
 * @deprecated
 */
const KontentItemDonationHeaderAfrica = ({ data }) => {
  const intl = useIntl();

  const donationHeader = data.value ? data.value[0] : data;

  if (!donationHeader || !donationHeader.elements) {
    return null;
  }

  const providers = KontentItemProvider({ data: donationHeader.elements.payment_providers });

  return (
    <DonationHeader
      backgroundImage={backgroundImageHelper(donationHeader.elements.hero_image, { w: 1920 })}
    >
      <DonationBox
        className="bg-white"
        title={donationHeader.elements.headline.value}
        text={donationHeader.elements.info.value}
        buttonText={intl.formatMessage({ id: "donation_box.donate_now" })}
        provider={providers}
      />
    </DonationHeader>
  );
};

export const query = graphql`
  fragment KontentItemDonationHeaderAfrica on kontent_item_money_donation_header_africa {
    id
    system {
      codename
    }
    elements {
      info {
        value
      }
      headline {
        value
      }
      hero_image {
        value {
          ...KontentItemImage
        }
      }
      impact_text {
        value
      }
      payment_providers {
        value {
          ...KontentItemProvider 
        }
      }
    }
  }
`;

KontentItemDonationHeaderAfrica.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemDonationHeaderAfrica;
