import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import InfoEmbed from "../../components/infoEmbed/infoEmbed";
import KontentAssetsImage from "../kontentAssetsImage";

const KontentItemSimpleTile = ({ data }) => {
  const simpleTileDataList = data.value;

  if (!simpleTileDataList.length > 0) {
    return null;
  }

  const tileList = [];

  simpleTileDataList.forEach((tile) => {
    tileList.push(
      <InfoEmbed
        key={tile.id}
        description={tile.elements.text.value}
        image={tile.elements.image.value.length > 0
          ? (
            <KontentAssetsImage
              data={tile.elements.image.value[0]}
              width={90}
              height={90}
            />
          )
          : null}
      />,
    );
  });

  return tileList;
};

export const query = graphql`
  fragment KontentItemSimpleTile on kontent_item_simple_tile {
    id
    system {
      codename
    }
    elements {
      text {
        value
      }
      image {
        value {
          description
          height
          size
          name
          type
          url
          width
        }
      }
    }
  }
`;

KontentItemSimpleTile.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemSimpleTile;
