import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import DonationAccount from "../../components/donationAccount/donationAccount";
import SEO from "../../components/seo";
import mapKontentItem from "../../utils/mapKontentItem";
import KontentItemRelatedLearnContentEmbed from "../kontentItemRelatedLearnContentEmbed";
import KontentItemTextEft from "../kontentItemTextEft";
import MainLayout from "../layout/mainLayout";
import KontentItemDonationHeaderAfrica from "../moneyDonation/kontentItemDonationHeaderAfrica";
import KontentItemDonationSectionMdAfr from "../moneyDonation/kontentItemDonationSectionMdAfr";
import KontentItemKeyFactAreaMoneyDonation from "../moneyDonation/kontentItemKeyFactAreaMoneyDonation";
import KontentItemMoneyPartition from "../moneyDonation/kontentItemMoneyPartition";
import KontentItemSimpleTile from "../moneyDonation/kontentItemSimpleTile";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";

const KontentItemMoneyDonationPageAfrica = ({ data }) => {
  const { page } = data;

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <SubNavigationGenerator id={page.id} />

      <KontentItemDonationHeaderAfrica data={page.elements.md_header} />

      <BreadcrumbGenerator id={page.id} />

      <DonationAccount
        text={KontentItemTextEft({ data: page.elements.text_eft })}
        infoEmbed={KontentItemSimpleTile({ data: page.elements.mobile_payments })}
      />

      <KontentItemKeyFactAreaMoneyDonation data={page.elements.key_fact_area} />

      <KontentItemMoneyPartition data={page.elements.data_visualization} />

      <KontentItemDonationSectionMdAfr data={page.elements.donation_section} />

      <KontentItemRelatedLearnContentEmbed data={page.elements.related_learn_content} />

      {data.page.elements.page_sections.value.map((item) => mapKontentItem(item,
        { faqSectionWithImage: true }))}

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemMoneyDonationPageAfrica(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__meta_title {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        key_fact_area {
          value {
            ...KontentItemKeyFactAreaMoneyDonation
          }
        }
        related_learn_content {
          value {
            ...KontentItemRelatedLearnContentEmbed
          }
        }
        page_sections {
          value {
            internal {
              type
            }
            ...KontentItemProgramSliderSection
            ...KontentItemFaqSection        
            ...KontentItemEyecatcherContact
            ...KontentItemIconCardSliderSection
            ...KontentItemEyecatcher
          }
        }
        data_visualization {
          value {
            ...KontentItemMoneyPartition
          }
        }
        md_header {
          value {
            ...KontentItemDonationHeaderAfrica
          }
        }
        text_eft {
          value {
            ...KontentItemTextEft
          }
        }
        mobile_payments {
          value {
            ...KontentItemSimpleTile
          }
        }
        donation_section {
          value {
            ...KontentItemDonationSectionMdAfr
          }
        }
      }
    }
  }
`;

KontentItemMoneyDonationPageAfrica.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemMoneyDonationPageAfrica;
