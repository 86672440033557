import React from "react";

import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import DonationBox from "./donationBox";
import KontentItemProvider from "./kontentItemProvider";
import DonationHeaderAfricaText from "../../components/donationHeader/donationHeaderAfricaText";
import KontentItemTextEft from "../kontentItemTextEft";

const KontentItemDonationSectionMdAfr = ({ data }) => {
  const intl = useIntl();

  const donationHeader = data.value ? data.value[0] : data;

  if (
    !donationHeader
    || !donationHeader.elements
    || donationHeader.elements.money_donation_header_africa.value.length === 0
  ) {
    return null;
  }

  const headerComponent = donationHeader.elements.money_donation_header_africa.value[0].elements;

  const providers = KontentItemProvider({ data: headerComponent.payment_providers });

  return (
    <DonationHeaderAfricaText
      description={KontentItemTextEft({ data: donationHeader.elements.eft })}
      headline={donationHeader.elements.headline.value}
      info={headerComponent.info.value}
      impactText={headerComponent.impact_text.value}
    >
      <DonationBox
        title={headerComponent.headline.value}
        text={headerComponent.info.value}
        buttonText={intl.formatMessage({ id: "donation_box.donate_now" })}
        provider={providers}
      />
    </DonationHeaderAfricaText>
  );
};

export const query = graphql`
  fragment KontentItemDonationSectionMdAfr on kontent_item_donation_section_md_afr {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      money_donation_header_africa {
        value {
          ...KontentItemDonationHeaderAfrica
        }
      }
      eft {
        value {
          ...KontentItemTextEft 
        }
      }
    }
  }
`;

KontentItemDonationSectionMdAfr.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemDonationSectionMdAfr;
