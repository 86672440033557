import React from "react";

import PropTypes from "prop-types";

import "./infoEmbed.scss";

const InfoEmbed = (props) => {
  const {
    image,
    description,
  } = props;

  return (
    <div className="info-embed">
      <div className="info-embed__image">{image}</div>
      <div className="info-embed__description">{description}</div>
    </div>
  );
};

InfoEmbed.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default InfoEmbed;
